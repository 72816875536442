<template>
    <v-app>
      <v-app-bar app color="primary" dark>
        <div class="d-flex">
          <v-btn text @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="d-flex align-center">
            <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../assets/logo-white.png"
            transition="scale-transition"
            width="25"
            />
          </div>
          <div class="d-flex align-center">
            <h2>Zobaze Home</h2>
            <h2
            class ="ml-4 grey--text text--lighten-1"
            @click="renderGolkondaCrm"
            type="button"
            >
            CRM
          </h2>
          <h2
            class ="ml-4 grey--text text--lighten-1"
            @click="renderGolkondaMine"
            type="button"
            >
            Mine
          </h2>
        </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn v-if="isLoggedIn" @click="logout" text class="pa-0">
          <span class="mr-1">Logout</span>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
      <template>
        <v-main>
          <v-container fluid>
            <v-container class="pa-1">
              <v-item-group v-model="selected">
                <v-row>
                  <v-col
                    v-for="(realm, i) in realms"
                    :key="i"
                    cols="6"
                    md="6"
                  >
                    <v-item>
                      <v-card
                        class="align-center"
                        height="120"
                        color="#e8edfc"
                        @click="selectAccount(realm)"
                        v-if="realm.id === 'zobaze'"
                      >
                        <div class="account-info text-center">
                          <v-avatar color="rgb(188,188,255)">
                            <span class="primary--text">{{
                              realm.id.substr(0, 2).toUpperCase()
                            }}</span>
                          </v-avatar>
                          <div class="display-1">{{ realm.name }}</div>
                        </div>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-container>
          </v-container>
          <select-account></select-account>
        </v-main>
      </template>
    </v-app>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
import selectAccount from './partner/select-account.vue';
  export default {
    components: { 
      selectAccount
    },
    data() {
      return {
        selected: null,
      };
    },
    computed: {
      ...mapGetters({
        isLoggedIn: "user/isLoggedIn",
        realms: "realm/realms",
        activeRealmId: "realm/activeRealmId",
        setActiveAccount: "partner/setActiveAccount"
      }),
    },
    methods: {
      ...mapActions({
        setActiveRealmId: "realm/setActiveRealmId",
        setLogout: "user/logout",
      }),
      selectAccount(realm) {
        this.setActiveRealmId(realm.id);

        this.$router.push("/master/customer");
      },
      logout() {
        this.setLogout()
        this.$router.push("/login");
      },
      goBack() {
        this.$router.go(-1);
      },
      renderGolkondaCrm() {
        this.$router.push("/golkonda/crm");
      },
      renderGolkondaMine() {
        this.$router.push("/golkonda/mine");
      },
    },
  };
  </script>
  
  <style>
  .home {
    min-height: 100%;
  }
  
  .centered-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  
  .account-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  </style>
  